import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('tag', [
  'hasIcon',
  'category',
  'status',
  'primary',
  'secondary',
  'default',
  'highlight1',
  'highlight2',
  'success',
  'warning',
  'error',
]);

const getTagCss = css`
  display: inline-flex;
  align-items: center;
  padding: 0 var(--cds-spacing-100);
  border: 1px solid;
  border-radius: var(--cds-border-radius-50);

  &.${classes.hasIcon} {
    padding: 0 var(--cds-spacing-100) 0 var(--cds-spacing-50);
  }

  &.${classes.category}.${classes.primary}.${classes.default} {
    background-color: var(--cds-color-neutral-disabled);
    border-color: var(--cds-color-neutral-disabled);
  }

  &.${classes.category}.${classes.primary}.${classes.highlight1} {
    color: var(--cds-color-neutral-primary-invert);
    background-color: var(--cds-color-callouts-secondary);
    border-color: var(--cds-color-callouts-secondary);
  }

  &.${classes.category}.${classes.primary}.${classes.highlight2} {
    background-color: var(--cds-color-callouts-secondary-invert);
    border-color: var(--cds-color-callouts-secondary-invert);
  }

  &.${classes.category}.${classes.secondary}.${classes.default} {
    border-color: var(--cds-color-neutral-primary-weak);
  }

  &.${classes.category}.${classes.secondary}.${classes.highlight1} {
    border-color: var(--cds-color-callouts-secondary);
  }

  &.${classes.category}.${classes.secondary}.${classes.highlight2} {
    border-color: var(--cds-color-callouts-secondary-invert);
  }

  &.${classes.status}.${classes.primary}.${classes.default} {
    color: var(--cds-color-neutral-primary-invert);
    background-color: var(--cds-color-neutral-primary-weak);
    border-color: var(--cds-color-neutral-primary-weak);
  }

  &.${classes.status}.${classes.primary}.${classes.highlight1} {
    color: var(--cds-color-neutral-primary-invert);
    background-color: var(--cds-color-callouts-tertiary);
    border-color: var(--cds-color-callouts-tertiary);
  }

  &.${classes.status}.${classes.primary}.${classes.success} {
    color: var(--cds-color-neutral-primary-invert);
    background-color: var(--cds-color-feedback-success);
    border-color: var(--cds-color-feedback-success);
  }

  &.${classes.status}.${classes.primary}.${classes.warning} {
    color: var(--cds-color-neutral-primary);
    background-color: var(--cds-color-yellow-200);
    border-color: var(--cds-color-yellow-200);
  }

  &.${classes.status}.${classes.primary}.${classes.error} {
    color: var(--cds-color-neutral-primary-invert);
    background-color: var(--cds-color-feedback-error);
    border-color: var(--cds-color-feedback-error);
  }

  &.${classes.status}.${classes.secondary}.${classes.default} {
    color: var(--cds-color-neutral-primary-weak);
    background-color: var(--cds-color-neutral-background-primary-weak);
    border-color: var(--cds-color-neutral-background-primary-weak);
  }

  &.${classes.status}.${classes.secondary}.${classes.highlight1} {
    color: var(--cds-color-callouts-tertiary);
    background-color: var(--cds-color-callouts-background-primary-weak);
    border-color: var(--cds-color-callouts-background-primary-weak);
  }

  &.${classes.status}.${classes.secondary}.${classes.success} {
    color: var(--cds-color-feedback-success);
    background-color: var(--cds-color-feedback-background-success-weak);
    border-color: var(--cds-color-feedback-background-success-weak);
  }

  &.${classes.status}.${classes.secondary}.${classes.warning} {
    color: var(--cds-color-feedback-warning);
    background-color: var(--cds-color-feedback-background-warning-weak);
    border-color: var(--cds-color-feedback-background-warning-weak);
  }

  &.${classes.status}.${classes.secondary}.${classes.error} {
    color: var(--cds-color-feedback-error);
    background-color: var(--cds-color-feedback-background-error-weak);
    border-color: var(--cds-color-feedback-background-error-weak);
  }

  svg {
    margin-right: var(--cds-spacing-50);
  }
`;

export default getTagCss;
