/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import GrowthDiscovery from 'bundles/epic/data/defaults/GrowthDiscovery.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  showMultiModuleRerankingDebugScores: boolean;
  fullStoryBrowseEnabled: boolean;
  enablePendoForGateways: boolean;
  algoliaIndexTypeToIndexNameMap: any;
  algoliaIndexTypeOrder: any;
  shouldDenylistOnboardingExisting: boolean;
  showCourseraPlusPillOnSearch: boolean;
  useOnboarding2021Flow: 'control' | 'skippableSurvey' | 'skippableQuestions';
  rolloutMediumOnboarding2021Flow: boolean;
  showNewRecsOnLOMainBrowse: boolean;
  showNewRecsOnLIDomain: boolean;
  showNewRecsOnLODomain: boolean;
  enableLOHPFullstory: boolean;
  algoliaSyllabusIndexing: 'control' | 'keywords' | 'phrases';
  newOnboardingExperiment2022: 'control' | 'variantB' | 'variantC';
  lihpDayZeroContextId: string;
  showEvolveCollection: boolean;
  removeLinksAboveFoldXDP: boolean;
  showGeneratedProductCardImage: boolean;
  enableLIHPTranslation: boolean;
  showEvolveCollectionBrowse: boolean;
  newBadgeStylesOnLihpProductCards: boolean;
  searchFilterUiVariant: 'control' | 'B' | 'C' | 'D' | 'E';
  showUnifiedHomeSandbox: boolean;
  showUnifiedHome: boolean;
  showBiggerCTAonXDP: boolean;
  cSerpProductCardDensitySkills: boolean;
  cSerpProductCardDensityRating: boolean;
  userLevelLearningGoal: 'control' | 'B' | 'C';
  enableQ1ConsumerSoftnessCollectionReordering: boolean;
  showLOHPHeroCarousel: 'control' | 'B';
  showRatingsandReviewsOnLIHPProductCards: boolean;
  showRatingsandReviewsOnLOHPProductCards: boolean;
  showRecentlyUpdatedBadge: boolean;
  enableEnterpriseGenAIHubPages: boolean;
  useStreamlinedMWebXDP: boolean;
  serpFilterVariant: 0 | 1 | 2;
  showSerpListProductCards: boolean;
  showNewAutocomplete: boolean;
  showNewAutocompleteDogfooding: boolean;
  useDynamicDegreePathway: boolean;
  useStreamlinedMWebLOHP: 'control' | 'B' | 'C';
  showNewMegamenu: boolean;
  useSkillCertificatePCDPLabel: boolean;
  showSeptemberAICampaignBadgeAndBanner: boolean;
  showHorizontalKIMOnXDP: boolean;
  enableUnifiedHomeBffCourseCard: boolean;
  courseraPlusLandingPageRedesign: 'control' | 'a' | 'b';
  truncateResultsCountSERP: 'control' | 'truncate-500' | 'no-count';
  showCPlusCtaOnGenAiHubPage: 'control' | 'a';
  showPathwaysSessionBased: 'control' | 'B' | 'C';
  showPathwaysUserBased: 'control' | 'B' | 'C';
  showUpdatedNoResultsPage: boolean;
  enableSelfServePageFullStory: boolean;
  showNewSERPSuggestedSearch: boolean;
  alwaysShowSearchBar: boolean;
  LOHPBucketTest: 'control' | 'A' | 'B';
  googleRefreshCollectionLOHPRollout: boolean;
};

const NAMESPACE = 'GrowthDiscovery';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([GrowthDiscovery as $TSFixMe]);

const GrowthDiscoveryEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default GrowthDiscoveryEpicClient;
