import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';

export const showPathwaysUserBased = () => {
  return GrowthDiscoveryEpicClient.get('showPathwaysUserBased') !== 'control';
};

export const getPathwaysUserBasedVariant = () => {
  return GrowthDiscoveryEpicClient.preview('showPathwaysUserBased');
};

export const showPathwaysSessionBased = () => {
  return GrowthDiscoveryEpicClient.get('showPathwaysSessionBased') !== 'control';
};

export const getPathwaysSessionBasedVariant = () => {
  return GrowthDiscoveryEpicClient.preview('showPathwaysSessionBased');
};

export const showPathwaysXDP = () => {
  if (
    GrowthDiscoveryEpicClient.get('showPathwaysSessionBased') !== 'control' ||
    GrowthDiscoveryEpicClient.preview('showPathwaysUserBased') !== 'control'
  ) {
    return true;
  }

  return false;
};

export const showFullPathwaysRDP = () => {
  const userBasedPathwaysVariant = getPathwaysUserBasedVariant();

  // make impression for session-base experiment
  GrowthDiscoveryEpicClient.get('showPathwaysSessionBased');

  if (userBasedPathwaysVariant !== 'control') {
    return true;
  }

  return false;
};

export const showPathwaysCareerAcademyPages = () => {
  const userBasedPathwaysVariant = getPathwaysUserBasedVariant();

  // make impression for session-base experiment
  const sessionBasedPathwaysVariant = GrowthDiscoveryEpicClient.get('showPathwaysSessionBased');

  if (userBasedPathwaysVariant !== 'control') {
    return true;
  }

  return sessionBasedPathwaysVariant !== 'control';
};
